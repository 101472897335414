import PropTypes from 'prop-types'
import React from 'react'
import {graphql} from 'gatsby'
import {ThemeContext} from '../layouts'
import Blog from '../components/Blog'
import Hero from '../components/Hero'
import Seo from '../components/Seo'
function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}
class IndexPage extends React.Component {
  separator = React.createRef()

  scrollToContent = e => {
    this.separator.current.scrollIntoView({block: 'start', behavior: 'smooth'})
  }

  render() {
    const {
      data: {
        posts: {edges: posts = []},
        bgDesktop: {
          resize: {src: desktop},
        },
        bgTablet: {
          resize: {src: tablet},
        },
        bgMobile: {
          resize: {src: mobile},
        },
        site: {
          siteMetadata: {facebook},
        },
      },
    } = this.props
    posts.forEach(post => {
      const {
        node: {
          body: {
            childMarkdownRemark: {excerpt},
          },
          slug,
          title,
          date,
          category,
          author: {name: author},
        },
      } = post
      const prefix = formatDate(date)
      post.node.fields = {
        slug,
        prefix,
      }
      const cover = null
      post.node.excerpt = excerpt
      post.node.frontmatter = {
        excerpt,
        title,
        category,
        author,
        cover,
      }
    })
    const backgrounds = {
      desktop,
      tablet,
      mobile,
    }

    return (
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => (
            <Hero
              scrollToContent={this.scrollToContent}
              backgrounds={backgrounds}
              theme={theme}
            />
          )}
        </ThemeContext.Consumer>
        <hr ref={this.separator} />
        <ThemeContext.Consumer>
          {theme => <Blog posts={posts} theme={theme} />}
        </ThemeContext.Consumer>

        <Seo facebook={facebook} />

        <style jsx>{`
          hr {
            margin: 0;
            border: 0;
          }
        `}</style>
      </React.Fragment>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

//eslint-disable-next-line no-undef
export const query = graphql`
  query IndexQuery {
    posts: allContentfulPost(limit: 1000, sort: {fields: [date], order: DESC}) {
      edges {
        node {
          body {
            childMarkdownRemark {
              excerpt
            }
          }
          date
          slug
          title
          subtitle
          category
          author {
            name
          }
          cover {
            fluid(maxWidth: 800, maxHeight: 360) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    bgDesktop: imageSharp(fluid: {originalName: {regex: "/hero-background/"}}) {
      resize(width: 1200, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgTablet: imageSharp(fluid: {originalName: {regex: "/hero-background/"}}) {
      resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgMobile: imageSharp(fluid: {originalName: {regex: "/hero-background/"}}) {
      resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
        src
      }
    }
  }
`

//hero-background
